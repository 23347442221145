import loadable from "@loadable/component";

export const pages = {
  ShowMarkDown: loadable(() => import("./ShowMarkDown")),
  Declaration: loadable(() => import("./Declaration")),
  PolicyPrivacy: loadable(() => import("./PolicyPrivacy")),
  Support: loadable(() => import("./Support")),
  SweetPotatoUserPolicy: loadable(() => import("./SweetPotatoUserPolicy")),
  SweetPotatoPolicyPrivacy: loadable(() =>
    import("./SweetPotatoPolicyPrivacy")
  ),
};
